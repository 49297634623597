$reportDarkGray: #19232eff;

.ttg-podcast-pdf-page-content {
	@apply text-primary text-lg;
	white-space: pre-line;

	strong {
		font-weight: bolder;
	}

	ul {
		@apply list-disc list-inside;
	}

	ol {
		@apply list-decimal list-inside;
	}

	h1 {
		@apply pb-4;
		padding-top: 0 !important;
		font-size: 1.7rem;
		font-weight: bold;
		line-height: 1.2 !important;
	}

	h2 {
		font-size: 1.5rem;
		font-weight: bold;
		line-height: 1.2 !important;
	}

	h3 {
		font-size: 1.17rem;
		font-weight: bold;
	}

	h4 {
		font-weight: bold;
	}

	h5 {
		font-size: 0.83rem;
		font-weight: bold;
	}

	h6 {
		font-size: 0.67rem;
		font-weight: bold;
	}

	pre {
		font-family: Consolas, Monaco, monospace;
	}
}

.ttg-podcast-pdf {
	margin: 0;
	outline: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font-weight: inherit;
	vertical-align: baseline;

	.Page__content {
		@apply p-0;
	}

	.Page {
		@apply relative flex flex-col overflow-hidden bg-white mx-auto;
		height: 11in;
		width: 8.5in;
		justify-content: stretch;
		box-sizing: border-box;
	}

	.PageHeader {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 110px;
		background-color: $reportDarkGray;
		padding: 0 4rem;

		span {
			color: white;
		}
	}

	.PageContent {
		min-height: 46rem;
	}

	.PageFooter {
		border-top: 1px solid $reportDarkGray;

		&__layout {
			@apply flex flex-row justify-between items-center;
			margin-top: 8pt;
			margin-bottom: 13pt;
		}

		&__logo {
			img {
				width: 1.095in;
			}
		}

		&__textContainer {
			@apply flex items-center justify-center;
		}

		&__page {
			@apply flex justify-center items-center font-bold;
			font-size: 9pt;
		}

		&__pageTriangle {
			width: 1em;
			margin-left: 4px;
		}

		&__paragraph {
			@apply flex justify-center items-center font-bold leading-none;
			font-size: 9pt;

			&:first-child {
				@apply font-bold;

				&:after {
					@apply inline-block;
					content: '';
					margin: 0 8px;
					height: 1em;
					border-right: 2px solid black;
				}
			}
		}
	}
}
