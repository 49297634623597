.ttg-video-slider {
	.swiper {
		@apply overflow-hidden px-4;
		.swiper-wrapper {
			.swiper-slide {
				@apply my-4 h-auto #{!important};

				.video-slide {
					@apply h-full shadow-default;

					.video-title {
						@apply ttg-text-base cursor-pointer text-rose-500 font-semibold mb-4;
					}
				}
			}
		}

		#ttg-video-slider-left {
			@apply absolute z-50 shadow-default;
			top: calc(50% - 19px);
			left: 2px;
		}

		#ttg-video-slider-right {
			@apply absolute z-50 shadow-default;
			top: calc(50% - 19px);
			right: 2px;
		}
	}
}
