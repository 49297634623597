.ReactModal__Overlay {
	opacity: 0;
	transform: translateY(-100px);
	transition: all 250ms ease-in-out;
}

.ReactModal__Overlay--after-open {
	opacity: 1;
	transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
	opacity: 0;
	transform: translateY(-100px);
}
