.team-assessment-setup {
	.progress-bar {
		@apply flex flex-1 flex-col mx-4 mt-4;

		.title {
			@apply ttg-text-2xl text-gray-900 mb-4;
		}

		.items-container {
			@apply flex flex-col mb-4;

			@screen md {
				@apply flex-row;
			}

			.item {
				@apply flex flex-1 flex-row items-center border-l-4 border-b-0 border-transparent p-4 cursor-default;

				@screen md {
					@apply border-b-4 border-l-0;
				}

				.circle {
					@apply flex w-10 h-10 min-w-10 min-h-10 rounded-full border-2 border-rose-500 items-center justify-center;
				}

				.text {
					@apply ttg-text-xs uppercase ml-2 font-semibold text-gray-900;
				}

				&.completed {
					@apply cursor-pointer;
					.circle {
						@apply bg-rose-500 text-lg;
					}
				}

				&.active {
					@apply bg-white border-rose-500;

					.circle {
						@apply text-rose-500;
					}

					.text {
						@apply text-rose-500;
					}
				}

				&.future {
					.circle {
						@apply border-gray-500 text-gray-500;
					}

					.text {
						@apply text-gray-500;
					}
				}
			}
		}
	}

	.app-dropdown {
		max-width: 100%;
	}

	.app-dropdown-prefix {
		max-width: 100%;

		&__control {
			@apply h-auto #{!important};
			min-height: $input-height;
			max-width: 100%;
		}

		&__value-container {
			max-width: 100%;
		}
	}
}
