.ttg-find-assessment-step {
	@apply flex flex-col items-center w-full px-4 mt-9;

	.ttg-find-assessment-step-content {
		@apply flex flex-col items-center;
		max-width: 47.5rem;

		.ttg-find-assessment-request-icon {
			@apply text-gray-500;
			font-size: 4rem;
		}

		.ttg-find-assessment-email-icon {
			@apply text-green-500;
			font-size: 4rem;
		}

		.ttg-find-assessment-step-title {
			@apply text-2xl font-medium text-gray-800 text-center pb-2;
		}

		.ttg-find-assessment-step-subtitle {
			@apply text-lg text-center font-medium text-gray-500;
		}

		.ttg-find-assessment-step-actions {
			@apply flex flex-col items-center py-9 gap-4;

			.btn {
				min-width: auto;
				@screen sm {
					min-width: 16.9rem;
				}
			}

			.ttg-find-assessment-step-actions-back {
				@apply text-base text-center text-red-600 hover:text-red-700 font-medium cursor-pointer;
			}
		}
	}
}
