.ttg-assessment-main-layout {
	@apply flex flex-1 flex-col max-w-full;

	.ttg-assessment-main-layout-header {
		@apply fixed w-full shadow-sm bg-white z-50;
		.content {
			@apply flex container mx-auto;
			height: 64px;

			.logo {
				@apply flex items-center justify-center w-full md:w-64;
			}

			.nav {
				@apply hidden md:flex flex-1 flex-row justify-end items-stretch;
				height: 64px;

				.nav-item {
					@apply ttg-text-lg text-rose-500 hover:text-rose-600 font-medium px-4 flex items-center hover:bg-gray-50;
					height: 64px;

					&.app-icon {
						@apply font-bold px-6;
					}
				}
			}
		}
	}

	.ttg-assessment-main-layout-content {
		@apply flex container mx-auto mb-4;

		.ttg-assessment-main-layout-inner-content {
			@apply flex flex-1 flex-col max-w-full;

			.ttg-assessment-section {
				@apply flex flex-1 shadow-sm bg-white p-6 mx-4 mt-4 rounded-sm;

				&.inner-section {
					@apply p-0 mx-0 mt-6 shadow-none;
				}

				.ttg-assessment-template-editor {
					@apply flex flex-row justify-between items-center shadow-base bg-gray-50 mb-6;
					padding: 1rem 1rem;

					@screen sm {
						padding: 1rem 1.5rem;
					}

					.ttg-assessment-revert-button {
						&:hover {
							@apply border-gray-400 bg-white;
						}
					}

					.ttg-assessment-save-template {
						@apply text-sm text-center font-medium cursor-pointer ml-4;
						color: #c71f37;
						&:hover {
							color: darken(#c71f37, 5%);
						}
					}
				}
			}

			#ttg-team-assessment-setup-company-form,
			#ttg-team-assessment-setup-team-form,
			#ttg-individual-assessment-setup-form {
				.short-form {
					width: 100%;
					@screen lg {
						width: 70%;
					}
				}
			}
		}
	}

	.ttg-assessment-main-layout-footer {
		@apply flex items-center justify-center my-4;
	}
}

.ttg-assessment-widget {
	.ttg-assessment-info {
		@apply px-4 py-3 border-gray-100 border-b-1;
	}
	.app-icon {
		@apply pr-2 text-gray-600 ttg-text-2xl;
	}

	.ttg-assessment-logout-action {
		@apply flex items-center px-4 py-2 cursor-pointer hover:bg-gray-50;
	}
	.ttg-my-assessment-action a {
		@apply flex items-center justify-between px-4 py-2 cursor-pointer hover:bg-gray-50 border-gray-100 border-b-1;
	}
}

.ttg-survey {
	@apply flex items-center flow-root container mx-auto p-5 md:w-auto max-w-3xl min-w-0;

	#ttg-survey-first-name {
		@apply rounded-sm py-3 h-10;
	}

	#ttg-survey-last-name {
		@apply rounded-sm py-3 h-10;
	}
	#ttg-survey-email {
		@apply rounded-sm py-3 h-10;
	}

	#ttg-survey-text {
		@apply my-8 pb-1 font-bold text-lg;
	}

	#ttg-survey-newsletter {
		@apply py-3 my-6;
	}
}

.ttg-survey-take {
	@apply flex items-center flow-root container mx-auto p-5 md:w-auto max-w-6xl min-w-0 bg-white max-h-full;
}

.ttg-assessment-sidebar {
	.ttg-assessment-info {
		@apply py-3 border-gray-100 border-b-1;
	}
	.ttg-assessment-logout-action {
		@apply flex items-center py-3 cursor-pointer;

		.app-icon {
			@apply pr-2 text-gray-600 ttg-text-2xl;
		}
	}
}

.ttg-assessment-main-layout-sidebar {
	@apply flex flex-col py-4 ml-4;
	min-width: 270px;

	@screen xl {
		min-width: 270px;
	}

	.ttg-assessment-main-layout-sidebar-wrapper {
		@apply fixed;
		min-width: 270px;

		@screen xl {
			min-width: 270px;
		}

		.sidebar-item {
			@apply flex px-2 py-1 my-1 items-center cursor-pointer;
			&:hover {
				@apply bg-gray-100;
				.app-icon {
					@apply text-gray-800;
				}
			}
			.app-icon {
				@apply pr-2 text-gray-400 ttg-text-2xl;
			}
			.sidebar-image {
				@apply grayscale pr-2;
			}
			&.active {
				@apply bg-white text-red-700 shadow-sm;
				.app-icon {
					@apply text-red-700;
				}
				.sidebar-image {
					@apply grayscale-0;
				}
			}
		}
	}
}

.nav-mobile {
	@apply flex flex-1 flex-col justify-end border-y-2 border-gray-100;

	.nav-item {
		@apply my-4 ttg-text-base text-rose-500 font-medium;
	}
}

.tools-mobile-view {
	@apply absolute top-0 opacity-0;
	transform: translateX(-380px);
	transition: all 250ms ease-in-out;
}

.tools-mobile-view.opened {
	@apply block opacity-100;
	transform: translateX(0px);
}

.tools-mobile-view.closed {
	@apply opacity-0 hidden;
	transform: translateX(380px);
}

.sidebar-mobile-view {
	@apply opacity-0;
	transform: translateX(-380px);
	transition: all 250ms ease-in-out;
}

.sidebar-mobile-view.opened {
	@apply opacity-100;
	transform: translateX(0px);
}

.sidebar-mobile-view.closed {
	opacity: 0;
	transform: translateX(-380px);
}

.ttg-assessment-tools {
	.ttg-assessment-label {
		@apply text-xs font-medium tracking-wider uppercase text-gray-400 px-4;
	}
	.ttg-assessment-ttg-tools {
		@apply py-3 border-gray-100 border-b-1;
	}
	.ttg-assessment-wg-tools {
		@apply py-3;
	}
	.ttg-assessment-tool {
		@apply px-4 py-2 flex items-center justify-between cursor-pointer hover:bg-gray-50;

		.ttg-tool-title {
			@apply text-sm font-medium text-gray-800 ml-2;
			width: 250px;
		}
		.app-icon {
			@apply text-gray-400 ttg-text-xl;
		}
	}
}

/* #region Change password */
.ttg-password-requirements {
	@apply absolute bg-white z-50 shadow-md -mt-4 w-64 sm:w-96;

	.ttg-requirements-title {
		@apply ttg-text-sm font-medium border-b-1 border-gray-100 py-3 px-4;
	}

	.ttg-password-checklist {
		@apply py-3 px-4;

		li span {
			@apply ttg-text-sm font-normal opacity-100 pt-1 text-gray-700;
		}

		.app-icon {
			@apply ttg-text-base mr-2;
		}
	}
}
/* #endregion */

/* #region Addressbook */
.ttg-addressbook-header {
	@apply flex flex-row items-center flex-wrap justify-between;

	.btn {
		@apply mb-6;
	}
}

.ttg-addressbook-header-info {
	@apply flex flex-col mb-6;

	h1 {
		@apply ttg-text-2xl mb-1;
	}
	p {
		@apply ttg-text-sm text-gray-500;
	}
}

.ttg-addressbook-primary {
	@apply flex flex-col md:flex-row w-full border-gray-200 border-t-1;
}

.ttg-addressbook-alternate {
	@apply flex flex-col md:flex-row w-full border-gray-200 border-t-1;

	.ttg-address-information {
		@apply border-gray-200 border-b-1;

		&:last-of-type {
			@apply border-0;
		}
	}
}

.ttg-addressbook-label {
	@apply ttg-text-sm text-gray-500 font-medium flex-initial w-full md:w-1/3 pt-6 md:py-6;
}

.ttg-address-information {
	@apply flex flex-1 flex-col sm:flex-row justify-start sm:justify-between py-6;

	p {
		@apply text-sm text-gray-800 font-normal;
	}
}

.ttg-address-actions {
	@apply flex justify-start md:justify-end md:self-center;
}

.ttg-address-action {
	@apply flex items-center justify-around cursor-pointer px-2 py-1 hover:bg-gray-50 relative;

	&:after {
		@apply block absolute bg-gray-400;
		content: '';
		height: 16px;
		width: 1px;
		left: calc(99%);
	}

	&:last-of-type {
		&:after {
			@apply w-0;
		}
	}
}

.ttg-no-address-info-section {
	@apply flex flex-col items-center justify-center p-6;

	.ttg-no-address-p {
		@apply ttg-text-sm text-gray-800 text-center mt-4;
	}
}
/* #endregion */

/* #region Add address form */
.ttg-address-form {
	@apply p-8 bg-white;
}
/* #endregion */

.assessments-overview-main-table {
	.ttg-table-container {
		min-height: 480px;
	}

	.identifier {
		@apply no-underline #{!important};
	}

	.identifier:hover {
		a {
			@apply text-rose-600 underline #{!important};
		}
	}

	.assessment-overview-field {
		max-width: 350px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

/* #region Assessments Information bricks */
.assessments-stats {
	@apply bg-white border-1 border-gray-200;

	.assessment-type-container {
		@apply flex flex-col md:flex-row items-center justify-center h-24 md:h-12 border-1 border-gray-200;

		.assessment-type {
			@apply flex items-center justify-center flex-1 self-stretch text-gray-500 cursor-pointer;

			&.active {
				@apply text-white font-semibold bg-gray-800;
			}
		}
	}
	.assessment-status-results {
		@apply p-4 flex flex-wrap;

		.total-assessments-info {
			@apply text-base text-gray-800 font-semibold uppercase w-full mb-4;
		}

		.assessment-result {
			flex: 0 0 100%;
			@apply sm:flex-1 items-center justify-center text-sm font-medium text-center mx-0 sm:mx-2;

			div {
				height: 108px;
				@apply flex items-center justify-center border-1 border-gray-300 text-4xl font-normal mb-1 cursor-pointer;
			}
		}
	}
}
/* #endregion */

/* #region Assessments Overview Header */
.assessments-overview-header {
	@apply flex flex-col md:flex-row justify-between items-center md:items-start mx-4 mt-6;

	.title {
		@apply text-2xl text-gray-900 font-medium mb-4 md:mb-0;
	}

	.assessments-overview-setup-actions {
		@apply flex flex-col items-center;
	}

	.individual-assessment-setup-action {
		@apply text-base text-gray-800 font-normal mt-4;
	}
}
/* #endregion */

/* #region Licenses Overview Heander */
.licenses-overview-header {
	@apply flex flex-wrap flex-col md:flex-row justify-center md:justify-between items-center w-full text-center;

	.title {
		@apply text-2xl text-gray-900 font-medium mb-4;
	}

	.licenses-overview-header-actions {
		@apply flex flex-col md:flex-row justify-center items-center;
	}
}
/* #endregion */

/* #region Licenses Information tiles */
.licenses-stats {
	@apply bg-white;

	.licenses-status-results {
		@apply p-4 flex flex-wrap;

		.licenses-result {
			flex: 0 0 100%;
			@apply sm:flex-1 items-center justify-center text-sm font-medium text-center mx-0 sm:mx-2;

			div {
				height: 108px;
				@apply flex items-center justify-center border-1 border-gray-300 text-4xl font-normal mb-1;
			}
		}
	}
}

.licenses-overview-main-table {
	.ttg-table-container {
		min-height: 480px;
	}

	.identifier {
		@apply no-underline #{!important};
	}

	.identifier:hover {
		p {
			@apply text-rose-600 underline #{!important};
		}
	}

	.licenses-overview-email {
		max-width: 350px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.licenses-overview-field {
		max-width: 450px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
/* #endregion */
