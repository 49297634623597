.ttg-pdf-wrapper {
	background-color: #525659;

	@media not print {
		.Page {
			@apply bg-gray-50;
			size: 8.5in 11in;
			margin: 0 auto;
		}

		.Page + .Page {
			margin-top: 1rem;
		}
	}

	.Page {
		@apply flex flex-col overflow-hidden relative bg-gray-50;
		justify-content: stretch;
		min-height: 11in;
		width: 8.5in;
		box-sizing: border-box;

		&__content {
			@apply flex flex-1 flex-col relative;
			padding: 50px 50px 0 50px;
			justify-content: stretch;
			z-index: 2;
		}

		&__contentBg {
			position: absolute;
			z-index: 1;

			&--pyramid {
				bottom: 0.425in;
				right: -1.8in;
				width: 6.1in;
			}
		}

		&__footer {
			margin-left: 0.5in;
			margin-right: 0.5in;
			margin-bottom: 0.25in;
			flex: 0 0 auto;
		}
	}
}
