.notifications-container {
	@screen md {
		@apply relative;
	}

	.notification-content {
		@apply fixed top-0 left-0 right-0 bottom-0 bg-white shadow-lg hidden z-50;
		max-width: 100%;
		height: 100vh;

		@screen md {
			min-width: 470px;
			height: auto;
			@apply absolute left-auto top-auto bottom-auto;
		}

		.scrollable-content {
			@apply overflow-y-scroll;
			max-height: calc(100vh - 100px);

			@screen md {
				max-height: 450px;
			}
		}

		&.opened {
			@apply block;
		}
	}
}

#ttg-header-notifications {
	margin-top: 0.125rem;
	padding: 0 0.25rem;
	.badge {
		position: absolute;
		top: 5px;
		right: 0px;
		font-size: 9px;
		line-height: 12px;
		font-weight: 400;
		padding: 0px 2px;
		border-radius: 6.5px;
		min-width: 12px;
		background: #ef4444;
		color: white !important;
	}
}
.more-options-container {
	@apply relative;
	.action-container {
		@apply absolute bg-white text-base z-50 p-2 rounded-sm shadow-md m-0 right-0 w-40;
	}
}
