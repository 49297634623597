.ReactModal__Overlay {
	background-color: rgba(107, 114, 128, 0.75) !important;
	&.ReactModal__Overlay--after-open {
		z-index: 100;
	}
	.ReactModal__Content--after-open {
		padding: 0 !important;
		border: 0 !important;
		border-radius: 8px !important;
	}
}

body.ReactModal__Body--open {
	@apply overflow-hidden;
}

.slide-overlay {
	opacity: 0;
	transform: translateX(180px);
	transition: all 250ms ease-in-out;
	&.left {
		transform: translateX(-180px);
	}
	.ReactModal__Content--after-open {
		border-radius: 0px !important;
	}
}

.ReactModal__Overlay--after-open.slide-overlay {
	opacity: 1;
	transform: translateY(0px);
}

.ReactModal__Overlay--before-close.slide-overlay {
	opacity: 0;
	transform: translateX(180px);
	&.left {
		transform: translateX(-180px);
	}
}

.slide-overlay {
	@apply fixed top-0 left-0 right-0 bottom-0;
}

$small-modal-size: 390px;
$large-modal-size: 680px;

.slide-modal {
	@apply absolute top-0 left-auto right-0 bottom-auto h-screen w-full overflow-auto flex flex-row;
	max-width: $small-modal-size;

	&.left {
		@apply right-auto left-0;
		max-width: calc(#{$small-modal-size} - 50px);

		@screen md {
			max-width: $small-modal-size;
		}
	}

	.slide-over-content {
		@apply flex-1 h-screen max-h-screen max-w-full overflow-auto bg-gray-50;

		div {
			@apply max-w-full;
		}
	}

	&.large {
		max-width: $large-modal-size;

		&.left {
			@apply right-auto left-0;
			max-width: calc(#{$large-modal-size} - 50px);

			@screen md {
				max-width: $large-modal-size;
			}
		}
	}
}
