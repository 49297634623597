.edit-assessment-sidebar {
	@apply flex flex-col h-full;

	.app-dropdown {
		max-width: 100%;
	}

	.app-dropdown-prefix {
		max-width: 100%;

		&__control {
			@apply h-auto #{!important};
			min-height: $input-height;
			max-width: 100%;
		}

		&__value-container {
			max-width: 100%;
		}
	}
}
