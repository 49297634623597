.btn {
	@apply inline-flex px-4 py-2 ttg-text-sm font-medium tracking-wider text-center justify-center items-center border-1;
	min-height: $input-height;

	&.dropdown-btn {
		border-color: #c71f37 !important;
	}

	&.large {
		@apply px-8 py-6;
	}

	&:disabled {
		@apply bg-gray-300 opacity-50 cursor-not-allowed;
	}

	&.btn-primary {
		@apply bg-rose-500 text-white border-rose-500;

		&:hover {
			@apply bg-rose-600 border-rose-600;
		}
	}

	&.btn-secondary {
		@apply bg-white text-gray-800 border-gray-100;

		&:hover {
			@apply bg-gray-50 border-gray-200;
		}
	}

	&.btn-action {
		@apply bg-gray-50 rounded-full p-0 border-none ttg-text-lg text-gray-600;
		height: $input-height;
		width: $input-height;

		&:hover {
			@apply bg-gray-100;
		}
	}

	&.danger {
		&:hover {
			@apply text-rose-500;
		}
	}
}
