$reportLightGray: #d9d9d9ff;
$reportDarkGray: #19232eff;

$reportGreen: #00a14aff;
$reportYellow: #fff155ff;
$reportRed: #da1a32ff;

.AssessmentReport {
	.Page {
		background-color: white !important;

		&__content {
			padding-left: 0.8125in;
			padding-right: 0.5in;
		}
	}

	.BracketPanel {
		border: 0.5px solid $reportDarkGray;
		position: relative;
		align-items: center;

		&:before {
			@apply absolute block bg-white;
			content: '';
			top: -2px;
			bottom: -2px;
			left: 6px;
			right: 6px;
		}

		&__content {
			font-size: 11pt;
			color: $reportDarkGray;
			text-align: center;
			font-weight: bold;
			position: relative;
			padding: 5px 30px;
			margin-top: 0;
			margin-bottom: 0;

			&--attribution {
				margin-top: 7px;
				font-family: 'Montserrat', sans-serif;
				font-weight: normal;
				font-size: 10pt;
			}
		}
	}

	.Blurb {
		&__title,
		&__subTitle {
			@apply uppercase font-bold;
			color: $reportDarkGray;
			margin-bottom: 8px;
		}

		&__subTitle {
			color: $reportRed;
		}

		&__content {
			@apply font-normal;
			text-align: justify;
			word-spacing: -1px;
			color: $reportDarkGray;
			font-size: 11pt;
			line-height: 1.5;

			> em {
				font-weight: inherit;
			}

			> strong {
				font-weight: bold;
			}

			> p ~ p {
				margin-top: 1em;
			}
		}
	}

	.PageHeading {
		display: flex;
		align-items: baseline;
		margin-bottom: 0.5in;

		&__title {
			@apply relative font-bold;
			font-size: 20pt;
			line-height: 1.2;
			color: $reportDarkGray;

			&:after {
				@apply block;
				content: '';
				border-top: 1px solid $reportDarkGray;
				width: 0.97in;
				margin-top: 0.25in;
			}
		}

		&__image {
			margin-top: -100%;
			margin-left: 0.2in;
		}

		&__pagination {
			@apply italic;
			margin-left: 0.5em;
			font-size: 16pt;
			line-height: 1.2;
		}
	}

	.PageFooter {
		border-top: 1px solid $reportDarkGray;

		&__layout {
			@apply flex flex-row justify-between items-center;
			margin-top: 8pt;
			margin-bottom: 13pt;
		}

		&__logo {
			img {
				width: 1.095in;
			}
		}

		&__textContainer {
			@apply flex items-center justify-center;
		}

		&__page {
			@apply flex justify-center items-center font-bold;
			font-size: 9pt;
		}

		&__pageTriangle {
			width: 1em;
			margin-left: 4px;
		}

		&__paragraph {
			@apply flex justify-center items-center font-bold leading-none;
			font-size: 9pt;

			&:first-child {
				@apply font-bold;

				&:after {
					@apply inline-block;
					content: '';
					margin: 0 8px;
					height: 1em;
					border-right: 2px solid black;
				}
			}
		}
	}

	.CoverPage {
		&__layout {
			padding-top: 0.1in;
		}

		&__logoContainer {
			width: 2in;

			img {
				@apply w-full;
			}
		}

		&__title {
			@apply font-bold leading-none;
			margin-top: 0.75in;
			font-size: 50pt;
		}

		&__tagline {
			@apply relative font-bold;
			font-size: 13pt;
			margin-top: 0.325in;

			hr {
				@apply absolute left-0 text-left;
				width: 0.97in;
				margin-top: 0.25in;
			}
		}

		&__teamInfo {
			@apply font-normal;
			margin-top: 0.65in;
			font-size: 11pt;
		}
	}

	.Introduction {
		@apply overflow-hidden flex flex-col justify-between;

		&__layout {
			@apply relative;
			margin-top: 2in;
		}

		&__heading {
			@apply relative;

			&__icon {
				@apply absolute;

				width: 1in;
				left: 191px;
				top: -55px;

				img {
					@apply w-full;
				}
			}
		}

		&__subHeading {
			@apply font-bold uppercase;
			font-size: 0.9em;
			line-height: 1.4;
			margin-top: 2.4em;
		}

		&__mainText {
			margin-top: 2.2em;
			width: 4.5375in;
		}

		&__mainTextAside {
			margin-top: 0.75in;
		}
	}

	.TheModel {
		@apply hidden flex flex-col justify-between;

		&__layout {
			@apply relative w-full;
			padding-right: 0.3125in;
			margin-top: 1.5in;
		}

		&__content {
			@apply flex justify-between;
		}

		&__heading {
			@apply relative;

			&__icon {
				@apply absolute;
				width: 0.75in;
				left: 1.5in;
				top: -62px;

				img {
					@apply w-full;
				}
			}
		}

		&__mainText {
			width: 4in;
		}

		&__aside {
			&__paragraph {
				@apply block font-bold text-left;
				margin-top: 18.7px;
				margin-bottom: 18.7px;
				font-size: 14pt;
				line-height: 24pt;
				width: 2in;
			}
		}

		&__borderPanel {
			padding: 0.1in;
			padding-left: 0.3073in;
			border: 1px solid $reportLightGray;
			position: relative;
		}

		&__miniEmblem {
			@apply absolute bg-gray-50;
			$icon-size: 0.21in;
			border-left: none;
			left: -10px;
			top: 50%;
			height: $icon-size;
			width: $icon-size;

			img {
				position: relative;
				width: 100%;
			}

			&:before {
				@apply absolute block bg-gray-50;
				content: '';
				top: -10px;
				bottom: -10px;
				width: 100%;
			}
		}
	}

	.TheFiveDysfunctions {
		@apply overflow-hidden flex flex-col justify-between;

		&__layout {
			@apply relative w-full;
			padding-top: 0.4in;
		}

		&__content {
			@apply flex flex-col justify-start;
			margin-top: 0.2in;
		}

		&__item {
			&__layout {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-bottom: 0.3489in;
			}

			&__dysfunctionNum {
				flex: 0 0 auto;
				justify-content: space-between;
				position: relative;
			}

			&__numUnderlay {
				position: absolute;
				left: 50%;
				width: 118%;
				top: 50%;
				transform: translate(-50%, -50%);
				z-index: 0;
			}

			&__dysfunctionNumTitle {
				@apply font-bold uppercase relative;
				color: $reportRed;
				font-size: 11pt;
				z-index: 1;
			}

			&__dysfunctionDesc {
				margin-left: 0.35in;
				width: 5.15in;
			}

			&__dysfunctionDescTitle {
				@apply font-bold uppercase;
				color: $reportDarkGray;
				margin-bottom: 8px;
				font-size: 0.9em;
			}

			&__dysfunctionDescParagraph {
				@apply font-normal;
				color: $reportDarkGray;
				font-size: 11pt;
				line-height: 13pt;
			}
		}
	}

	.TheRewards {
		@apply overflow-hidden flex flex-col justify-between;

		&__layout {
			@apply relative;
			position: relative;
			margin-top: 1.5in;
		}

		&__heading {
			@apply relative;

			&__icon {
				@apply absolute;
				width: 0.7314in;
				left: 2.1571in;
				bottom: 25px;

				img {
					@apply w-full;
				}
			}
		}

		&__content {
			position: relative;
		}

		&__mainText {
			width: 4.8575in;
			margin-bottom: 0.38in;
		}

		&__aside {
			width: 5.7769in;

			&__paragraph {
				@apply font-bold text-left;
				padding: 0.32in;
				font-size: 14pt;
				line-height: 24pt;
			}
		}

		&__borderPanel {
			@apply relative;
			padding: 0.1in;
			border: 1px solid $reportLightGray;

			&:before {
				@apply block absolute bg-gray-50;
				content: '';
				height: 34px;
				width: 22px;
				left: -11px;
				top: 50%;
				margin-top: -17px;
			}
		}

		&__miniEmblem {
			position: absolute;
			border-left: none;
			background-color: white;
			left: -10px;
			top: 45%;
			width: 0.21in;

			img {
				width: 100%;
			}
		}
	}

	.TeamSummary {
		@apply overflow-hidden flex flex-col justify-between;

		&__layout {
			@apply relative w-full;
			padding-top: 0.4in;
		}

		&__content {
			@apply flex flex-col justify-between;
		}

		&__mainText {
			width: 4in;
			margin-bottom: 0.5in;
		}

		&__pyramidLayout {
			@apply flex relative justify-between;
		}

		&__pyramid {
			@apply relative text-right;
			z-index: 3;
			left: -40px;

			svg {
				width: 6in;
			}
		}

		&__pyramidLevels {
			@apply flex flex-col justify-self-stretch;
			font-size: 11pt;
			padding-bottom: 1em;

			.level {
				@apply flex flex-1;
				align-items: flex-end;

				span {
					@apply font-bold uppercase inline-block bg-gray-50 leading-none relative;
					color: $reportDarkGray;
					padding-right: 1em;
					z-index: 1;
				}

				&:after {
					@apply block absolute;
					content: '';
					background-color: $reportDarkGray;
					height: 1px;
					margin-top: -0.5em;
					width: 60%;
				}
			}
		}
	}

	.ScoresPyramid {
		fill: #e2e5e5ff;

		&--hideLabels {
			text {
				@apply hidden;
			}
		}

		.high {
			fill: $reportGreen;
		}

		.medium {
			fill: $reportYellow;
		}

		.low {
			fill: $reportRed;
		}
	}

	.ScoresLegend {
		@apply flex justify-between;
		margin-top: 0.4in;
		width: 6in;

		&__column {
			@apply flex flex-nowrap items-center;
		}

		&__key {
			color: gray;
			background: currentColor;
			width: 18px;
			height: 18px;
			margin-right: 10px;

			&--green {
				background: $reportGreen;
			}

			&--yellow {
				background: $reportYellow;
			}

			&--red {
				background: $reportRed;
			}
		}

		&__description {
			@apply uppercase font-normal;
			font-size: 0.8em;
			> strong {
				@apply font-bold;
			}
		}
	}

	.PyramidText {
		@apply uppercase font-bold;
		letter-spacing: -0.01em;
		font-size: 12px;
		fill: $reportDarkGray;
		font-weight: 700;
	}

	.ProgressScale {
		display: flex;
		justify-content: space-between;
		padding-bottom: 2.5em;

		&--withLeftLabel {
			padding-left: 0.6in;
		}

		&--withRightLabel {
			padding-right: 0.6in;
		}

		&__mark {
			border-left: 1px solid black;
			width: 0;
			height: 1em;
			position: relative;
		}

		&__markLabel {
			position: absolute;
			top: 100%;
			transform: translateX(-50%);
		}
	}

	.ProgressBar {
		display: flex;
		align-items: center;

		&__label {
			@apply font-normal;
			flex: 0 0 auto;
			width: 0.6in;
			font-size: 1.1em;
		}

		&__meter {
			flex: 1 1 auto;
			fill: #e2e5e5;
			height: 12px;
		}

		&__label {
			padding-left: 1em;
		}

		&__meterBar {
			width: 100%;
			height: 100%;
		}

		&__meterFill {
			height: 100%;
			fill: black;
		}

		&--low {
			fill: $reportRed;
		}

		&--medium {
			fill: $reportYellow;
		}

		&--high {
			fill: $reportGreen;
		}

		+ .ProgressBar {
			margin-top: 6px;
		}
	}

	.MarkLabel {
		@apply font-bold;

		&__number {
			padding-top: 0.4em;
			padding-bottom: 0.4em;
			text-align: center;
		}

		&__text {
			text-align: center;
			text-transform: uppercase;
			font-size: 0.5em;
			white-space: nowrap;
		}
	}

	.FiveBehaviors {
		@apply overflow-hidden flex flex-col justify-between;

		&__layout {
			padding-top: 0.2in;
		}

		&__heading {
			@apply relative;
		}

		&__content {
			@apply flex flex-col justify-between;
		}

		&__summary {
			padding-right: 0.4in;
		}

		&__summaryRow {
			display: flex;

			& + & {
				margin-top: 1.2em;
			}
		}

		&__summaryBehaviorColumn {
			@apply font-bold uppercase;
			color: $reportDarkGray;
			font-size: 12pt;
			width: 35%;
		}

		&__summaryMeterColumn {
			width: 60%;
		}

		&__descriptions {
			margin-top: 0.25in;
		}

		&__descriptionRow {
			display: flex;

			& + & {
				margin-top: 0.2in;
			}
		}

		&__descriptionPyramidColumn {
			flex: 0 0 auto;
			width: 0.925in;
			margin-right: 0.4in;
		}

		&__descriptionTextColumn {
			flex: 1 1 auto;
		}

		&__descriptionTitle {
			@apply font-bold uppercase;
			margin-bottom: 0.5em;
			font-size: 0.9em;
		}

		&__descriptionBody {
			@apply font-normal;
			font-size: 0.75em;
			line-height: 1.5;
		}
	}

	.AreaPage {
		@apply w-full;

		&__content {
			margin-right: 0.5in;
		}

		&__summary {
			margin-top: 0.5in;
			margin-bottom: 0.5in;
			max-width: 6in;
		}
	}

	.AreaHeading {
		$header-height: 50px;
		background: $reportDarkGray;
		border-top-right-radius: $header-height / 2;
		border-bottom-right-radius: $header-height / 2;
		display: flex;
		align-items: center;
		padding-left: 1em;

		&__title {
			@apply font-bold flex-1 text-gray-50;
			font-size: 20pt;
		}

		&__iconContainer {
			@apply bg-gray-50;
			border: 1.1px solid $reportDarkGray;
			border-radius: 50%;
			width: $header-height;
			height: $header-height;

			.rotate60 {
				transform: rotate(60deg);
			}
		}

		&__icon {
			fill: $reportDarkGray;
		}
	}

	.AreaQuestion {
		@apply flex font-normal;
		font-size: 0.9em;
		margin-top: 0.2in;

		&__number {
			flex: 0 0 auto;
			font-weight: bold;
			width: 0.5in;
		}

		&__content {
			flex: 1 1 auto;
		}

		&__label {
			font-weight: bold;
			line-height: 1.2;
		}

		&__categories {
			margin-top: 0.05in;
		}

		&__categoriesDescription {
			font-size: 0.85em;
		}

		&__scale {
			display: flex;
			padding: 0.15in 1px;
			font-size: 14pt;
		}

		&__scaleCol {
			@apply flex flex-1 items-center justify-center;
			border-left: 1px solid #e2e5e5ff;
			border-right: 1px solid #e2e5e5ff;
			padding: 0.05in 0.265in;

			& + & {
				margin-left: -1px;
			}
		}

		&__meta {
			$meta-padding: 0.64em;
			$meta-margin: 0.8em;
			background-color: #e2e5e5ff;
			outline: $meta-padding solid #e2e5e5ff;
			margin-top: $meta-padding + $meta-margin;
			margin-bottom: $meta-padding;
			display: flex;
			justify-content: space-between;
			text-transform: uppercase;

			strong {
				font-weight: bold;
			}
		}

		&__metaCol {
			font-size: 0.8em;
			line-height: 1.3em;

			&--small {
				font-size: 0.9em;
				text-transform: none;
			}
		}

		&__progressBar {
			margin-top: 0.1in;
		}

		&--condensed {
			& + & {
				margin-top: 0.2in;
			}
		}

		&--condensed &__progressBar {
			margin-top: 0.1in;
		}

		&--condensed &__meta {
			$meta-padding: 0.5em;
			$meta-margin: 0.6em;
			outline-width: $meta-padding;
			margin-top: $meta-padding + $meta-margin;
			margin-bottom: $meta-padding;
		}

		&--small {
			font-size: 0.8em;

			& + & {
				margin-top: 0.2in;
			}

			.AreaQuestion__meta {
				$meta-padding: 0.5em;
				$meta-margin: 0.6em;
				outline-width: $meta-padding;
				margin-top: $meta-padding + $meta-margin;
				margin-bottom: $meta-padding;
			}
		}
	}

	.TeamCultureQuestions {
		$top-section-max-width: 5.5in;

		&__layout {
			@apply w-full;
			padding-top: 0.4in;
		}

		&__intro {
			display: flex;
			align-items: center;
			background-color: #e2e5e5ff;
			margin-top: -0.3in;
			margin-bottom: 0.4in;
			padding: 0.3in;
			padding-left: 0.15in;
			max-width: $top-section-max-width + 0.3in;
		}

		&__introIcon {
			flex: 0 0 auto;
			width: 0.2125in;
			margin-right: 0.15in;
		}

		&__introContent {
			@apply font-normal;
			font-size: 0.9em;
			text-align: justify;
			line-height: 1.2;
			flex: 1 1 auto;
		}

		&__continuedText {
			@apply font-normal;
			font-size: 0.9em;
			font-style: italic;
			line-height: 1.4;
			margin-top: -0.3in;
			margin-bottom: 0.4in;
		}

		&__blurb {
			max-width: $top-section-max-width;
			margin-bottom: 0.2in;
		}

		&__questionReviewTitle {
			@apply font-bold;
			font-size: 0.9em;
			line-height: 1.4;
			margin-bottom: 0.15in;
			max-width: $top-section-max-width;
		}

		&__scoreMeterList {
			margin-top: 0.3in;
			max-width: 6.75in;
		}

		&__scoreMeterItem {
			& + & {
				margin-top: 0.2in;
			}
		}

		&__scoreMeterItemTitle {
			@apply font-normal;
			font-size: 0.8em;
			line-height: 1.4;
			margin-bottom: 0.1in;
			padding-right: 1.5in;
		}
	}

	.TallyList {
		&__item {
			@apply font-normal;
			font-size: 0.8em;
			display: flex;
			align-items: flex-start;
		}

		&__count {
			flex: 0 0 auto;
			width: 0.5in;
			padding: 0.09in 0;
			padding-right: 0.15in;
			text-align: right;
			font-weight: bold;
		}

		&__question {
			flex: 1 1 auto;
			border-left: 1px solid black;
			padding: 0.12in 0;
			padding-left: 0.15in;
			line-height: 1.2;
		}
	}

	.ConflictTable {
		@apply font-bold;

		&__headerCell {
			font-size: 0.5em;
			text-transform: uppercase;
			background-color: #e2e5e5ff;
			padding: 0.05in 0.075in;
			vertical-align: middle;

			&:first-child {
				text-align: left;
			}
		}

		&__headerCell--wideCell {
			width: 40%;
		}

		&__headerCell--noBG {
			background-color: transparent !important;
		}

		td {
			text-align: center;
			font-size: 0.8em;
			line-height: 1.4;
			vertical-align: middle;
			padding: 0.15in 0.075in;

			&:first-child {
				padding-left: 0;
				text-align: left;
				font-weight: 400;
			}
		}
	}

	.TeamScoreMeter {
		display: flex;

		&__meter {
			flex: 1 1 auto;
		}

		&__label {
			@apply font-bold;
			font-size: 0.8em;
			text-transform: uppercase;
			flex: 0 0 auto;
			white-space: nowrap;
			margin-left: 0.15in;
			width: 1.5in;
		}
	}

	.TeamAverage {
		$top-section-max-width: 5.5in;

		&__layout {
			position: relative;
		}

		&__introLayout {
			display: flex;
			align-items: center;
			margin-top: 0.4in;
			margin-bottom: 0.5in;
			max-width: 6.5in;
		}

		&__introPyramid {
			flex: 0 0 auto;
			width: 1.5in;
			margin-right: 0.15in;
		}

		&__introBlurb {
			flex: 1 1 auto;
		}

		&__summaryRow {
			margin-top: 0.25in;
			padding-left: 0.5in;
		}
	}

	.ItemRanking {
		width: 100%;

		&__content {
			margin-right: 0.5in;
		}

		&__summary {
			@apply font-normal;
			font-size: 0.9em;
			line-height: 1.4;
			margin-top: 0.3in;
			margin-bottom: 0.3in;
		}

		&--continued &__summary {
			margin-top: -0.3in;
		}

		&--redline {
			border-top: 2px dashed $reportRed;
		}
	}

	.TipsExercises {
		$bullet-width: 0.125in;

		&__layout {
			@apply w-full;
		}

		&__summary {
			@apply font-normal text-justify;
			font-size: 0.9em;
			line-height: 1.3;
			margin-top: -0.2in;
			margin-bottom: 0.5in;
			max-width: 6in;
		}

		&__tipsTitle {
			@apply font-bold uppercase;
			font-size: 0.9em;
			margin-bottom: 1.5em;
		}

		&__tip {
			& + & {
				margin-top: 1.5em;
			}
		}

		&__tipLayout {
			display: flex;
			align-items: baseline;
			max-width: 6in;
		}

		&__tipBullet {
			flex: 0 0 auto;
			width: $bullet-width * 2;
			padding-right: $bullet-width;
			line-height: 1;
		}

		&__tipBulletImage {
			display: inline-block;
			width: 100%;
		}

		&__tipContent {
			flex: 1 1 auto;
		}

		&__tipTitle {
			@apply font-bold;
			font-size: 0.9em;
			margin-bottom: 0.4em;
			line-height: 1;
		}

		&__tipTitleLink {
			color: #777;
			font-size: 0.8em;
			text-decoration: underline;
			font-weight: normal;
			margin-left: 1em;
			display: inline-block;
			background-color: #eee;
			border-radius: 2em;
			padding: 0.4em 1em;
			vertical-align: middle;
		}

		&__tipLinkContainer {
			@apply font-normal;
			font-size: 0.8em;
			margin-top: 1em;
			margin-bottom: 1em;
			font-style: italic;
		}

		&__tipLink {
			color: $reportRed;
			text-decoration: underline;
			font-style: normal;
			margin-left: 0.5em;
			letter-spacing: 0.5px;

			&.__no_margin {
				margin: 0;
			}
		}

		&__tipText {
			@apply font-normal mb-4;
			font-size: 0.9em;
			line-height: 1.35;
		}
	}
}

.AssessmentProgressReport {
	.CoverPage {
		&__layout {
			padding-top: 0.8in;
		}

		&__logoContainer {
			width: 2in;

			img {
				@apply w-full;
			}
		}

		&__title {
			@apply font-bold leading-none;
			margin-top: 0.75in;
			font-size: 50pt;
		}

		&__tagline {
			@apply relative font-bold;
			font-size: 13pt;
			margin-top: 0.325in;
			line-height: 1.5;

			hr {
				@apply absolute left-0 text-left;
				width: 0.97in;
				margin-top: 0.25in;
				line-height: 1.5;
			}
		}

		&__teamInfo {
			@apply font-normal;
			margin-top: 0.55in;
			font-size: 11pt;

			p {
				margin-top: 0.25in;
				line-height: 1.2rem;
			}
		}
	}

	.Introduction {
		@apply overflow-hidden flex flex-col justify-between;

		&__layout {
			@apply relative;
			margin-top: 0.5in;
		}

		&__heading {
			@apply relative;

			.PageHeading {
				margin-bottom: 0.3in;
			}

			&__icon {
				@apply absolute;
				width: 1in;
				left: 191px;
				top: -55px;

				img {
					@apply w-full;
				}
			}
		}

		&__subHeading {
			@apply font-bold uppercase;
			font-size: 0.9em;
			line-height: 1.4;
			margin-top: 2.4em;
		}

		&__mainText {
			margin-top: 2.2em;
			width: 4.5375in;
		}

		&__mainTextAside {
			margin-top: 0.3in;
		}
	}

	.PageFooter {
		border-top: 1px solid $reportDarkGray;

		&__layout {
			@apply flex flex-row justify-between items-center;
			margin-top: 8pt;
			margin-bottom: 13pt;
		}

		&__logo {
			img {
				width: 1.095in;
			}
		}

		&__textContainer {
			@apply flex justify-center items-center;
		}

		&__page {
			@apply flex justify-center items-center font-bold;
			font-size: 9pt;
		}

		&__pageTriangle {
			width: 1em;
			margin-left: 4px;
		}

		&__paragraph {
			@apply font-normal flex justify-center items-center leading-none;
			font-size: 9pt;

			&:first-child {
				@apply font-bold;

				&:after {
					content: '';
					display: inline-block;
					margin: 0 8px;
					height: 1em;
					border-right: 2px solid black;
				}
			}
		}
	}

	.AreaHeading {
		$header-height: 50px;
		background: $reportDarkGray;
		border-top-right-radius: $header-height / 2;
		border-bottom-right-radius: $header-height / 2;
		display: flex;
		align-items: center;
		padding-left: 1em;

		&__title {
			@apply font-bold;
			flex: 1 1 auto;
			color: white;
			font-size: 20pt;
		}

		&__iconContainer {
			background-color: white;
			border: 1.1px solid $reportDarkGray;
			border-radius: 50%;
			width: $header-height;
			height: $header-height;

			.rotate60 {
				transform: rotate(60deg);
			}
		}

		&__icon {
			fill: $reportDarkGray;
		}
	}

	.AreaPage {
		width: 100%;

		&__layout {
			padding-top: 0.5in;
			padding-right: 0.25in;
		}

		&__content {
			margin-right: 0.5in;
		}

		&__summary {
			margin-top: 0.5in;
			margin-bottom: 0.25in;
			max-width: 6in;
		}
	}

	.AreaQuestion {
		@apply flex font-normal;
		font-size: 0.9em;
		margin-top: 0.3in;

		&__number {
			flex: 0 0 auto;
			font-weight: bold;
			width: 0.5in;
		}

		&__content {
			flex: 1 1 auto;
		}

		&__label {
			font-weight: bold;
			line-height: 1.2;
		}

		&__categories {
			margin-top: 0.05in;
		}

		&__categoriesDescription {
			font-size: 0.85em;
		}

		&__scale {
			display: flex;
			padding: 0.15in 1px;
			font-size: 14pt;
		}

		&__scaleCol {
			@apply flex flex-1 items-center justify-center;
			border-left: 1px solid #e2e5e5;
			border-right: 1px solid #e2e5e5;
			padding: 0.05in 0.265in;

			& + & {
				margin-left: -1px;
			}
		}

		&__meta {
			$meta-padding: 0.34em;
			$meta-margin: 0.4em;
			background-color: #e2e5e5;
			outline: $meta-padding solid #e2e5e5;
			margin-top: $meta-padding + $meta-margin;
			margin-bottom: $meta-padding;
			display: flex;
			justify-content: space-between;
			text-transform: uppercase;

			strong {
				font-weight: bold;
			}
		}

		&__metaCol {
			font-size: 0.8em;
			line-height: 1.3em;

			&--small {
				font-size: 0.9em;
				text-transform: none;
			}
		}

		&__progressBar {
			margin-top: 0.05in;
		}

		&--condensed {
			& + & {
				margin-top: 0.2in;
			}
		}

		&--condensed &__progressBar {
			margin-top: 0.1in;
		}

		&--condensed &__meta {
			$meta-padding: 0.5em;
			$meta-margin: 0.6em;
			outline-width: $meta-padding;
			margin-top: $meta-padding + $meta-margin;
			margin-bottom: $meta-padding;
		}

		&--small {
			font-size: 0.8em;

			& + & {
				margin-top: 0.2in;
			}
		}

		&--small &__meta {
			$meta-padding: 0.5em;
			$meta-margin: 0.6em;
			outline-width: $meta-padding;
			margin-top: $meta-padding + $meta-margin;
			margin-bottom: $meta-padding;
		}
	}

	.Blurb {
		&__title,
		&__subTitle {
			@apply font-bold;
			text-transform: uppercase;
			color: $reportDarkGray;
			margin-bottom: 8px;
		}

		&__subTitle {
			color: $reportRed;
		}

		&__content {
			@apply font-normal;
			text-align: justify;
			word-spacing: -1px;
			color: $reportDarkGray;
			font-size: 11pt;
			line-height: 1.5;

			> em {
				font-weight: inherit;
			}

			> strong {
				font-weight: bold;
			}

			> p ~ p {
				margin-top: 1em;
			}
		}
	}

	.BracketPanel {
		border: 0.5px solid $reportDarkGray;
		position: relative;
		align-items: center;

		&:before {
			@apply block absolute bg-white;
			content: '';
			top: -2px;
			bottom: -2px;
			left: 6px;
			right: 6px;
		}

		&__content {
			font-size: 11pt;
			color: $reportDarkGray;
			line-height: 13pt;
			text-align: center;
			font-weight: bold;
			position: relative;
			padding: 5px 25px;
			margin-top: 0;
			margin-bottom: 0;

			&--attribution {
				margin-top: 7px;
				font-family: 'Montserrat', sans-serif;
				font-weight: normal;
				font-size: 10pt;
			}
		}
	}

	.ConflictTable {
		@apply font-bold;

		&__headerCell {
			font-size: 0.5em;
			text-transform: uppercase;
			background-color: #e2e5e5;
			padding: 0.05in 0.075in;
			vertical-align: middle;

			&:first-child {
				text-align: left;
			}
		}

		&__headerCell--wideCell {
			width: 40%;
		}

		&__headerCell--noBG {
			background-color: transparent !important;
		}

		td {
			text-align: center;
			font-size: 0.8em;
			line-height: 1.4;
			vertical-align: middle;
			padding: 0.15in 0.075in;

			&:first-child {
				padding-left: 0;
				text-align: left;
				font-weight: 400;
			}
		}
	}

	.DysfunctionAverage {
		$top-section-max-width: 5.5in;

		&__layout {
			@apply relative w-full;

			.PageHeading {
				margin-bottom: 0.35in;
			}

			.AreaQuestion {
				&__number,
				&__label {
					font-size: 1.1em;
				}

				& + .AreaQuestion {
					margin-top: 0.265in;
				}
			}

			.ProgressBar {
				&__label {
					width: 1in;
					font-size: 13px;

					&--score {
						font-weight: 700;
					}
				}

				&__meter {
					margin-right: 0.6in;
				}
			}
		}

		&__summaryRow {
			margin-top: 0.265in;
			padding-left: 1.5in;
		}
	}

	.ItemRanking {
		width: 100%;

		&__content {
			margin-right: 0.5in;
		}

		&__summary {
			@apply font-normal;
			font-size: 0.9em;
			line-height: 1.4;
			margin-top: 0.3in;
			margin-bottom: 0.2in;
		}

		&--continued &__summary {
			margin-top: -0.2in;
		}

		&--redline {
			border-top: 2px dashed $reportRed;
		}
	}

	.MarkLabel {
		@apply font-bold;

		&__number {
			padding-top: 0.4em;
			padding-bottom: 0.4em;
			text-align: center;
		}

		&__text {
			text-align: center;
			text-transform: uppercase;
			font-size: 0.5em;
			white-space: nowrap;
		}
	}

	.Page {
		@apply flex flex-col overflow-hidden relative;
		justify-content: stretch;
		height: 11in;
		width: 8.5in;
		box-sizing: border-box;
		background-color: white !important;

		&__content {
			padding-left: 0.8125in !important;
			padding-right: 0.5in !important;
			flex: 1 1 auto;
			display: flex;
			flex-direction: row;
			justify-content: stretch;
			position: relative;
			z-index: 2;
		}

		&__contentBg {
			position: absolute;
			z-index: 1;

			&--pyramid {
				bottom: 0.425in;
				right: -1.8in;
				width: 6.1in;
			}
		}

		&__footer {
			margin-left: 0.5in;
			margin-right: 0.5in;
			margin-bottom: 0.25in;
			flex: 0 0 auto;
		}
	}

	.PageHeading {
		display: flex;
		align-items: baseline;
		margin-bottom: 0.5in;

		&__title {
			font-family: 'Montserrat', sans-serif;
			font-size: 20pt;
			line-height: 1.2;
			font-weight: bold;
			position: relative;
			color: $reportDarkGray;

			&:after {
				content: '';
				border-top: 1px solid $reportDarkGray;
				display: block;
				width: 0.97in;
				margin-top: 0.25in;
			}
		}

		&__image {
			margin-top: -100%;
			margin-left: 0.2in;
		}

		&__pagination {
			margin-left: 0.5em;
			font-family: 'Montserrat', sans-serif;
			font-size: 16pt;
			font-stretch: italic;
			line-height: 1.2;
		}
	}

	.ProgressBar {
		display: flex;
		align-items: center;

		&__label {
			@apply font-normal;
			flex: 0 0 auto;
			width: 0.6in;
			font-size: 1.1em;
		}

		&__meter {
			flex: 1 1 auto;
			fill: #e2e5e5;
			height: 12px;
		}

		&__label {
			//padding-left: 1em;
		}

		&__meterBar {
			width: 100%;
			height: 100%;
		}

		&__meterFill {
			height: 100%;
			fill: black;
		}

		&--low {
			fill: $reportRed;
		}

		&--medium {
			fill: $reportYellow;
		}

		&--high {
			fill: $reportGreen;
		}
	}

	.ProgressScale {
		display: flex;
		justify-content: space-between;
		padding-bottom: 2.5em;

		&--withLeftLabel {
			padding-left: 0.6in;
		}

		&--withRightLabel {
			padding-right: 0.6in;
		}

		&__mark {
			border-left: 1px solid black;
			width: 0;
			height: 1em;
			position: relative;
		}

		&__markLabel {
			position: absolute;
			top: 100%;
			transform: translateX(-50%);
		}
	}

	.ScoresPyramid {
		fill: #e2e5e5;

		.high {
			fill: $reportGreen;
		}

		.medium {
			fill: $reportYellow;
		}

		.low {
			fill: $reportRed;
		}
	}

	.ScoresLegend {
		margin-top: 0.4in;
		/* width: 6in; */
		height: 1in;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&__column {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
		}

		&__key {
			color: gray;
			background: currentColor;
			width: 18px;
			height: 18px;
			margin-right: 10px;

			&--red {
				background: $reportRed;
			}

			&--yellow {
				background: $reportYellow;
			}

			&--green {
				background: $reportGreen;
			}
		}

		&__description {
			@apply font-normal;
			text-transform: uppercase;
			font-size: 0.8em;

			> strong {
				font-weight: bold;
			}
		}
	}

	.TallyList {
		&__item {
			@apply font-normal;
			font-size: 0.8em;
			display: flex;
			align-items: flex-start;
		}

		&__count {
			flex: 0 0 auto;
			width: 0.5in;
			padding: 0.07in 0;
			padding-right: 0.15in;
			text-align: right;
			font-weight: bold;
		}

		&__question {
			flex: 1 1 auto;
			border-left: 1px solid black;
			padding: 0.09in 0;
			padding-left: 0.15in;
			line-height: 1.2;
		}
	}

	.TeamCultureQuestions {
		$top-section-max-width: 5.5in;

		&__layout {
			padding-top: 0.5in;
			width: 100%;
		}

		&__intro {
			display: flex;
			align-items: center;
			background-color: #e2e5e5;
			margin-top: -0.3in;
			margin-bottom: 0.4in;
			padding: 0.3in;
			padding-left: 0.15in;
			max-width: $top-section-max-width + 0.3in;
		}

		&__introIcon {
			flex: 0 0 auto;
			width: 0.2125in;
			margin-right: 0.15in;
		}

		&__introContent {
			@apply font-normal;
			font-size: 0.9em;
			text-align: justify;
			line-height: 1.2;
			flex: 1 1 auto;
		}

		&__continuedText {
			@apply font-normal;
			font-size: 0.9em;
			font-style: italic;
			line-height: 1.4;
			margin-top: -0.3in;
			margin-bottom: 0.4in;
		}

		&__blurb {
			max-width: $top-section-max-width;
			margin-bottom: 0.4in;
		}

		&__questionReviewTitle {
			@apply font-bold;
			font-size: 0.9em;
			line-height: 1.4;
			margin-bottom: 0.15in;
			max-width: $top-section-max-width;
		}

		&__scoreMeterList {
			margin-top: 0.3in;
			max-width: 6.75in;
		}

		&__scoreMeterItem {
			& + & {
				margin-top: 0.2in;
			}
		}

		&__scoreMeterItemTitle {
			// @apply font-bold;
			@apply font-normal;
			font-size: 0.8em;
			line-height: 1.4;
			margin-bottom: 0.1in;
			padding-right: 1.5in;
		}
	}

	.TeamScoreMeter {
		display: flex;

		&__meter {
			flex: 1 1 auto;
		}

		&__label {
			@apply font-bold;
			font-size: 0.8em;
			text-transform: uppercase;
			flex: 0 0 auto;
			white-space: nowrap;
			margin-left: 0.15in;
			width: 1.5in;
		}
	}

	.TeamSummary {
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.PageHeading {
			margin-bottom: 0;
		}

		&__layout {
			margin-top: 0.5in;
			width: 100%;
			position: relative;
		}

		&__content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&__pyramidLayout {
			position: relative;
			top: 0.75in;
			display: flex;
			justify-content: space-between;
		}

		&__pyramid {
			position: relative;
			left: -40px;
			z-index: 3;
			text-align: right;

			svg {
				width: 6in;
			}
		}

		&__pyramidLevels {
			display: flex;
			flex-direction: column;
			justify-content: stretch;
			font-size: 11pt;
			padding-bottom: 1em;

			.level {
				flex: 1 1 auto;
				display: flex;
				align-items: flex-end;

				span {
					@apply font-bold bg-gray-50 uppercase inline-block leading-none relative;
					color: $reportDarkGray;
					padding-right: 1em;
					z-index: 1;
				}

				&:after {
					content: '';
					background-color: $reportDarkGray;
					height: 1px;
					display: block;
					position: absolute;
					margin-top: -0.5em;
					width: 60%;
				}
			}
		}

		&__Dates {
			margin-top: 0.85in;
			text-align: right;

			span {
				width: 287px;
				@apply font-bold uppercase inline-block bg-gray-50 relative text-center leading-none;
				font-size: 13px;
				color: $reportDarkGray;
				z-index: 1;
			}
		}
	}

	.FiveBehaviors {
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&__layout {
			padding-top: 0.5in;
			width: 100%;
		}

		&__heading {
			position: relative;

			.PageHeading {
				margin-bottom: 0.3in;
			}
		}

		&__content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&__summary {
			padding-right: 0.4in;
		}

		&__summaryRow {
			display: flex;

			& + & {
				margin-top: 1.2em;
			}
		}

		&__summaryBehaviorColumn {
			@apply font-bold;
			color: $reportDarkGray;
			font-size: 11pt;
			text-transform: uppercase;
			/* width: 35%; */
			width: 25%;
			display: flex;
			align-items: center;
		}

		&__summaryMeterColumn {
			width: 75%;
			margin-bottom: 6px;

			.ProgressScale {
				padding-right: 1.1in;
			}

			.ProgressBar {
				&__meter {
					height: 11px;
				}

				&__label {
					width: 1.1in;
					font-size: 12px;

					&--score {
						font-weight: 700;
					}
				}
			}
		}

		&__descriptions {
			/* margin-top: 0.25in; */
			margin-top: 0.45in;
		}

		&__descriptionRow {
			display: flex;
			margin-top: 0.175in;
		}

		&__descriptionPyramidColumn {
			flex: 0 0 auto;
			/* width: 0.925in; */
			width: 0.825in;
			/* margin-right: 0.4in; */
			margin-right: 0.25in;
		}

		&__descriptionTextColumn {
			display: flex;
			flex: 1 1 auto;
			flex-direction: column;
			justify-content: center;
		}

		&__descriptionTitle {
			@apply font-bold;
			text-transform: uppercase;
			margin-bottom: 0.5em;
		}

		&__descriptionBody {
			@apply font-normal;
			font-size: 0.75em;
			line-height: 1.5;
		}
	}

	.TheFiveDysfunctions {
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&__layout {
			padding-top: 0.5in;
			position: relative;
			width: 100%;
		}

		&__content {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			margin-top: 0.5in;
		}

		&__item {
			&__layout {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-bottom: 0.3489in;
			}

			&__dysfunctionNum {
				flex: 0 0 auto;
				justify-content: space-between;
				position: relative;
			}

			&__numUnderlay {
				position: absolute;
				left: 50%;
				width: 118%;
				top: 50%;
				transform: translate(-50%, -50%);
				z-index: 0;
			}

			&__dysfunctionNumTitle {
				@apply font-bold;
				color: $reportRed;
				font-size: 11pt;
				text-transform: uppercase;
				position: relative;
				z-index: 1;
			}

			&__dysfunctionDesc {
				margin-left: 0.35in;
				width: 5.15in;
			}

			&__dysfunctionDescTitle {
				@apply font-bold;
				text-transform: uppercase;
				color: $reportDarkGray;
				margin-bottom: 8px;
				font-size: 0.9em;
			}

			&__dysfunctionDescParagraph {
				@apply font-normal;
				color: $reportDarkGray;
				font-size: 11pt;
				line-height: 13pt;
			}
		}
	}

	.TipsExercises {
		$bullet-width: 0.125in;

		&__layout {
			padding-top: 0.5in;
			width: 100%;
		}

		&__summary {
			@apply font-normal;
			font-size: 0.9em;
			line-height: 1.3;
			margin-top: -0.2in;
			margin-bottom: 0.5in;
			max-width: 6in;
			text-align: justify;
		}

		&__tipsTitle {
			@apply font-bold;
			text-transform: uppercase;
			font-size: 0.9em;
			margin-bottom: 1.5em;
		}

		&__tip {
			margin-top: 1.5em;
		}

		&__tipLayout {
			display: flex;
			align-items: baseline;
			max-width: 6in;
		}

		&__tipBullet {
			flex: 0 0 auto;
			width: $bullet-width * 2;
			padding-right: $bullet-width;
			line-height: 1;
		}

		&__tipBulletImage {
			display: inline-block;
			width: 100%;
		}

		&__tipContent {
			flex: 1 1 auto;
		}

		&__tipTitle {
			@apply font-bold;
			font-size: 0.9em;
			margin-bottom: 0.4em;
			line-height: 1;
		}

		&__tipTitleLink {
			color: #777;
			font-size: 0.8em;
			text-decoration: underline;
			font-weight: normal;
			margin-left: 1em;
			display: inline-block;
			background-color: #eee;
			border-radius: 2em;
			padding: 0.4em 1em;
			vertical-align: middle;
		}

		&__tipLinkContainer {
			@apply font-normal;
			font-size: 0.8em;
			margin-top: 1em;
			margin-bottom: 1em;
			font-style: italic;
		}

		&__tipLink {
			color: $reportRed;
			text-decoration: underline;
			font-style: normal;
			margin-left: 0.5em;
			letter-spacing: 0.5px;
		}

		&__tipText {
			@apply font-normal mb-4;
			font-size: 0.9em;
			line-height: 1.35;
		}

		&.overcoming-avoidance-acc {
			.TipsExercises__layout {
				padding-top: 0;
			}
			.TipsExercises__summary {
				margin-bottom: 0.2in;
			}
		}
	}
}
