.app-datepicker {
	&-input {
		@apply flex appearance-none items-center px-1 py-0 bg-white outline-none border-1 border-gray-300 ttg-text-sm rounded-none w-full cursor-pointer;
		-webkit-appearance: none;
		-moz-appearance: none;
		height: $input-height;
		transition: box-shadow 0.1s ease-in-out;

		.date {
			@apply flex flex-1;
		}
	}
}
