.dropdown-container {
	@apply relative flex flex-1 justify-end;
	overflow: visible;
	.btn div {
		display: flex;
		align-items: center;
	}
}

.action-container {
	@apply absolute bg-white text-base z-50 rounded-sm shadow-md py-1 m-0;
	width: max-content;
	z-index: 1000;
	top: 100%;

	&.left {
		@apply right-0;
	}

	&.right {
		@apply left-0;
	}
}
